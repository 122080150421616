import { Injectable } from '@angular/core';
import { ClientType } from 'src/app/utils/const';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private _clientType!: string
  isAuthenticated: boolean = false

  constructor() {
    this._clientType = sessionStorage.getItem("client") || ClientType.HUB;
  }

  getClientType() {
    return this._clientType;
  }

  setClientType(value: string) {
    this._clientType = value;
  } 
}
