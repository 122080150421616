import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Product } from '../models/product';
import { Patient } from './image.service';
import { ProposerModule } from '../proposer.module';


export interface Treatment {
  _id: string
  name: string
}

@Injectable({
  providedIn: ProposerModule
})
export class DataService {

  url_be = environment.url_be.concat("/api/v1");
  constructor(private http: HttpClient) {
  }

  getVersion() {
    return this.http.get<string>(`${environment.url_be}/version`);
  }

  deleteCategory(category: string) {
    return this.http.delete<any[]>(this.url_be + "/users/products", {
      withCredentials: true, 
      params: {
        category: category
      }
    });
  }

  putCategory(category: string, newCategory: string) {
    return this.http.put<any[]>(this.url_be + "/users/products", {category: newCategory}, {
      withCredentials: true, 
      params: {
        category: category
      }
    });
  }

  deleteProduct(productId: string) {
    return this.http.delete<any[]>(this.url_be + "/users/products/" + productId, {withCredentials: true});
  }

  createProduct(fd: FormData) {
    return this.http.post<{payload: {product: Product}}>(this.url_be.concat('/users/products'), fd, {withCredentials: true})
  }

  putProduct(fd: FormData, uuid: string) {
    return this.http.put<{payload: {product: Product}}>(this.url_be.concat(`/users/products/${uuid}`), fd, {withCredentials: true})
  }

  getProductImage(productId: string) {
    return this.http.get<{payload: {uri: string}}>(this.url_be.concat('/users/products/', productId), { withCredentials: true })
  }

  exportReport(file: File, products: string[], patient: Patient, index: number) {
    const fd: FormData = new FormData();
    fd.append('image', file);
    fd.append('products', JSON.stringify(products));
    if(patient) {
      fd.append('firstName', patient.first_name || '')
      fd.append('name', patient.last_name || '')
      fd.append('birthdate', patient.date_of_birth || '')
    }
    return this.http.post<{uuid: string}>(this.url_be + '/users/pdf/' + index, fd, {withCredentials: true})
  }

  getPdf(uuid: string) {
    return this.http.get<{url: string}>(this.url_be.concat('/users/pdf'), {withCredentials: true, params: {uuid: uuid}})
  }

  // new
  getProducts() {
    return this.http.get<{payload: {products: Product[]}}>(this.url_be.concat('/users/products'), { withCredentials: true })
  }

  restoreDefault() {
    return this.http.post<{payload: {products: Product[]}}>(this.url_be.concat('/users/products/default'), {}, {withCredentials: true})
  }

  saveSettings(color: string, lineWidth: number, fontSize: number, highlightWidth: number, highlightOpacity: number, radius: number) {
    return this.http.put(this.url_be.concat('/users/drawing-settings'), {
      color: color,
      fontSize: fontSize,
      lineWidth: lineWidth,
      highlightOpacity: highlightOpacity,
      highlightWidth: highlightWidth,
      injectionsRadius: radius
    }, {withCredentials: true})
  }

  getSettings() {
    return this.http.get<{
      injectionsRadius: number;color: string, fontSize: number, lineWidth: number, highlightWidth: number, highlightOpacity: number
}>(this.url_be.concat('/users/drawing-settings'), {withCredentials: true})
  }

  getTreatments() {
    return this.http.get<Treatment[]>(`${this.url_be}/users/treatments`, {withCredentials: true})
  }

  putTreatment(id: string, name: string) {
    return this.http.put(`${this.url_be}/users/treatments/${id}`, {name}, {withCredentials: true})
  }

  postTreatment(name: string) {
    return this.http.post<Treatment>(`${this.url_be}/users/treatments`,{name}, {withCredentials: true})
  }

  delTreatment(t: Treatment) {
    return this.http.delete(`${this.url_be}/users/treatments/${t._id}`, {withCredentials: true})
  }

  removeBackground(uuid: string, url: string, blob: Blob | null = null) {
    const PRP_t = sessionStorage.getItem("PRP_t") || "";
    const headers = new HttpHeaders({ "access-token": PRP_t });
    const fd = new FormData();
    fd.append("uuid", uuid);
    fd.append("url", url);
    if(blob) fd.append("file", blob);
    return this.http.post<{url: string}>(`${this.url_be}/removebg`, fd, { withCredentials: true, headers })
  }
}
