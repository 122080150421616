import { TranslateService } from '@ngx-translate/core';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  OnInit,
  HostListener
} from '@angular/core';
import { COLOR_PICKER, FONT_SIZES, HIGHLIGHT_OPACITY, HIGHLIGHT_SIZES, LINE_SIZES, MODE_BUTTONS, TOOLBAR_ITEMS } from '../../../utils/items';
import { CanvasComponent } from './canvas/canvas.component';
import { UnitInputPipe } from '../../pipes/unit-pipe/unit-input.pipe';
import { MlInputPipe } from '../../pipes/ml-pipe/ml-input.pipe';
import { ImageFilterService } from '../../services/image-filter.service';
import cv, { Mat } from 'opencv-ts';
import cloneDeep from 'lodash.clonedeep';
import { DataService } from '../../services/data.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from '../../services/modal.service';
import { ClientType, DrawingConstant } from 'src/app/utils/const';
import { UserActionService } from 'src/app/shared/services/user-action.service';
import { AppService } from 'src/app/shared/services/app.service';
import {OverlayScrollbarsComponent} from "overlayscrollbars-ngx";
import { base64ToBlob, canvasToBlob, urlToBase64 } from 'src/app/utils/image';
import { ObjectUrlService } from '../../services/object-url.service';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.css'],
})
export class ImageEditorComponent implements AfterViewInit, OnInit, OnDestroy {

  // constructor
  constructor(private _modal: ModalService, private _spinner: NgxSpinnerService,
    private unitPipe: UnitInputPipe, private mlPipe: MlInputPipe, private _objectUrlService: ObjectUrlService,
    private imageFilterService: ImageFilterService, private dataService: DataService, private _appService: AppService,
    private _userActionService: UserActionService, private _translate: TranslateService
  )
  {
    this._translate.get('imageEditor.loadingImage').subscribe((value: string) => {
      this.loadingText = value;
    });
    this._translate.get('imageEditor.someErrorAppear').subscribe((value: string) => {
      this._ngxTryAgainText = value;
    });
    this._translate.get('imageEditor.pleaseWaitUntilImageIsReady').subscribe((value: string) => {
      this._ngxImageReadyText = value;
    });
    this._translate.get('imageEditor.someErrorHasAppear').subscribe((value: string) => {
      this._ngxSomeErrorText = value;
    });
    this._translate.get("imageEditor.faceSimulation").subscribe((value: string) => {
      this._ngxFace = value;
    });
    this.unitsValues = Array(33).fill(0).map((x, i) => i);
    this.mlValues = Array(24).fill(0).map((x, i) => i);
  }
  ngOnInit(): void {
    this._userActionService.lockFace$.subscribe(() => this.isFaceEnabled = false);
    this._userActionService.filterMode$.subscribe(v => this.filterMode = v)
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get('imageEditor.loadingImage').subscribe((value: string) => {
        this.loadingText = value;
      });
      this._translate.get('imageEditor.someErrorAppear').subscribe((value: string) => {
        this._ngxTryAgainText = value;
      });
      this._translate.get('imageEditor.pleaseWaitUntilImageIsReady').subscribe((value: string) => {
        this._ngxImageReadyText = value;
      });
      this._translate.get('imageEditor.someErrorHasAppear').subscribe((value: string) => {
        this._ngxSomeErrorText = value;
      });
      this._translate.get("imageEditor.faceSimulation").subscribe((value: string) => {
        this._ngxFace = value;
      });
    })
  }
  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this._onLangChangeSub.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this._setUpDrawingSettings();
    this.onChangeUnit();
    this.onSelectTool(null, -1, -1);
    this.canvas.selectMode(-1);
  }


  // public method
  async getCroppedImage(original: boolean): Promise<Blob> {
    return await this.canvas.exportImage(original);
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  // event method

  onSliderMouseDown(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = true;
  }

  onSliderTouchStart(ev: TouchEvent): void {
    ev.preventDefault();
    this._isSliding = true;
  }

  onContainerTouchEnd(): void {
    //ev.preventDefault();
    this._isSliding = false;
  }

  onContainerTouchMove(ev: any): void {
    if (!this._isSliding) return;
    if (ev.touches.length > 1) return;
    const rect = ev.currentTarget.getBoundingClientRect();
    const offsetX = ev.touches[0].clientX - rect.left;
    this.sliderLeft = offsetX > 0 ? offsetX * 100 / rect.width : 0;
    if (this.sliderLeft > 100) this.sliderLeft = 100
    this._sliderEv = ev;
    if (this._filterMode > 0)
      this.canvas.modifyOverlayImage(ev);
    ev.stopPropagation();
  }

  onContainerMouseUp(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = false;
  }

  onContainerMouseLeave(ev: MouseEvent): void {
    ev.preventDefault();
    this._isSliding = false;
  }

  onContainerMouseMove(ev: any): void {
    if (!this._isSliding) return;
    const rect = ev.currentTarget.getBoundingClientRect();
    const offsetX = ev.clientX - rect.left;
    this.sliderLeft = offsetX > 0 ? offsetX * 100 / rect.width : 0;
    this._sliderEv = ev;
    if (this._filterMode > 0)
      this.canvas.modifyOverlayImage(ev);
  }

  onSelectTool(ev: any, mode: number, index: number): void {
    if(ev) {
      const isActive = ev.currentTarget.classList.contains('bg-ffred');
      document.querySelectorAll('.item.selectable').forEach((e) => {
        e.classList.remove('bg-ffred');
      });
      if(!isActive) ev.currentTarget.classList.add('bg-ffred');
    }
    let value = 0;
    this.isCrossOrPoint = false;
    this.toolbarItems.forEach((itemGroups, i) => {
      itemGroups.forEach((item, j) => {
        if (i === mode && j === index) {
          item.isActive = !item.isActive;
          if (item.isActive) {
            if (value == 10 || value == 11) this.isCrossOrPoint = true;
            this.canvas.selectMode(value);
          } else {
            this.canvas.selectMode(-1);
          }
        }
        else item.isActive = false;
        value++;
      })
    })
  }

  onToggleSettingsTab(value: boolean) {
    this.isSettingsTabShown = value;
  }

  saveSettings() {
    try {
      // check hub ?
      if (this.isHub) {

        lastValueFrom(
          this.dataService.saveSettings(
            this.drawingColor,
            this.lineWidth,
            this.fontSize,
            this.highlightWidth,
            this.highlightOpacity,
            this.injectionsRadius
          )
        );
        console.log('Settings saved to dataservice successfully.');
      } else {
        // save settings to local storage
        localStorage.setItem('drawingColor', this.drawingColor);
        localStorage.setItem('lineWidth', this.lineWidth.toString());
        localStorage.setItem('fontSize', this.fontSize.toString());
        localStorage.setItem('highlightWidth', this.highlightWidth.toString());
        localStorage.setItem('highlightOpacity', this.highlightOpacity.toString());
        localStorage.setItem('injectionsRadius', this.injectionsRadius.toString());
        console.log('Settings saved to localStorage successfully.');
      }
    } catch (error) {
      console.error('Error saving settings:', error);
    }
  }

  onChangeUnit() {
    if (this.unit) {
      this.canvas.unit = 'ml';
      this.onChangeMlValue();
    }
    else {
      this.canvas.unit = 'units';
      this.onChangeUnitValue();
    }
  }

  onChangeUnitValue() {
    this.canvas.value = this.unitPipe.transform(this.currentUnitValue);
  }

  onChangeMlValue() {
    this.canvas.value = this.mlPipe.transform(this.currentMlValue);
  }

  onFaceSimulation() {
    this.faceSimulation.emit();
    this._userActionService.announceTitleChanged(this._ngxFace);
  }
  // Logic methods

  hasChanged() {
    return this.canvas && (this.canvas.hasChanged() || !this.hasBackground)
  }

  onUnRedo(value: number) {
    if (value == 0) this.canvas.undoCanvas();
    else this.canvas.redoCanvas();
  }

  onUncrop() {
    this.canvas.uncrop()
  }

  onTriggerDelete() {
    document.dispatchEvent(new KeyboardEvent("keydown", { keyCode: 46 }));
  }

  async onToggleBackground(value: boolean) {
    if (!value) {
      this._spinner.show('background');
      if (this.bgImage) {
        this.canvas.changeBackground(this.bgImage);
        this.hasBackground = value;
      } else {
        try {
          this.isProcessingBackground = true;
          if(this._appService.getClientType() == ClientType.MEESMA) {
            const uuid = sessionStorage.getItem("room") as string;
            // imageData can be url or a raw base64 string
            const file = this._imageData.includes("http") ? base64ToBlob(await urlToBase64(this._imageData)) : base64ToBlob(this._imageData);
            this.bgImage = (await lastValueFrom(this.dataService.removeBackground(uuid, "", file))).url;
          } else {
            this.bgImage = (await lastValueFrom(this.dataService.removeBackground(this.uuid, this._imageData))).url
          }
          this.isProcessingBackground = false;
          this._spinner.hide('background');
          this._modal.openConfirm("Remove background", "Background has been successfully processed. Do you want to remove it?")
            .then(isReplaced => {
              if(isReplaced) {
                this._spinner.show('filter-spinner');
                this.canvas.changeBackground(this.bgImage);
                this.hasBackground = value;
              }
            })
        } catch (err: any) {
          this._modal.openNotification(this._ngxSomeErrorText);
          this.isProcessingBackground = false;
          this._spinner.hide('background');
        }
      }
    } else {
      this._spinner.show('filter-spinner')
      this.canvas.changeBackground(this._imageData)
      this.hasBackground = value
    }
  }

  onSelectLineSize(e: any, size: number, index: number) {
    this.lineSizeChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.lineSize = color.width;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectFontSize(e: any, size: number, index: number) {
    this.fontSizeChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.fontSize = color.size;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectHighlightSize(e: any, size: number, index: number) {
    this.highlightSizeChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.highlightSize = color.width;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectHighlightOpacity(e: any, size: number, index: number) {
    this.highlightOpacityChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.highlightOpacity = color.opacity;
      }
    })
    this.canvas.resetBrush();
  }

  onSelectColor(e: any, index: number) {
    this.colorChoices.forEach((color, i) => {
      color.active = 0;
      if (index === i) {
        color.active = 1;
        this.canvas.drawingColor = color.color;
      }
    })
    this.canvas.resetBrush();
  }

  onRulerFlip() {
    this.ruler.isTop = !this.ruler.isTop;
    if (!this.ruler.isTop)
      this.ruler.top = 'calc(100% - 100px)';
    else this.ruler.top = '10px'
  }

  onChangeFilterMode(ev: MouseEvent, mode: number) {
    if (!this._imageData) {
      this._modal.openNotification(this._ngxImageReadyText);
      return;
    }
    if (mode) {
      this._spinner.show('filter-spinner')
    }
    this._filterMode = mode;
    this.filterModeChange.emit(mode);
    this._filterImage(mode);
  }
  onFileChanged(event: any) {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    // đang cho đọc nhiều files ??
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this._userActionService.announceUploadFaceImage(reader.result as string)
    };
    reader.readAsDataURL(files[0]);



  }

  // private method
  private _filterImage(mode: number) {
    switch (mode) {
      case 0:
        this.canvas?.removeOverlayImage();
        break;
      case 3:
        if (this._imageFilterURL.evenness) {
          this.canvas.setOverlayImage(this._imageFilterURL.evenness, this._sliderEv);
          this._spinner.hide('filter-spinner')
          return;
        }
        setTimeout(async () => {
          try {
            if (!this._bgr)
              this._bgr = cv.imread(this.imgFilter.nativeElement);
            const rs = this.imageFilterService.filterEvenness(this._bgr);
            cv.imshow(this.canvasFilter.nativeElement, rs);
            rs.delete();
            this._imageFilterURL.evenness = this._objectUrlService.createObjectUrl(await canvasToBlob(this.canvasFilter.nativeElement, 'image/jpeg', 0.95));
            this.canvas.setOverlayImage(this._imageFilterURL.evenness, this._sliderEv);
            this._spinner.hide('filter-spinner')
          } catch (err) {
            console.log(err)
            this._spinner.hide('filter-spinner')
            this._modal.openNotification(this._ngxTryAgainText);
            this.filterModeChange.emit(0)
          }
        }, 100);
        break;
      // case 3:
      //   if (this._imageFilterURL.redness_v2) {
      //     this.canvas.setOverlayImage(this._imageFilterURL.redness_v2, this._sliderEv);
      //     this._spinner.hide('filter-spinner')
      //     return;
      //   }
      //   setTimeout(() => {
      //     try {
      //       if (!this._bgr)
      //         this._bgr = cv.imread(this.imgFilter.nativeElement);
      //       const rs = this.imageFilterService.filterRedness2(this._bgr);
      //       cv.imshow(this.canvasFilter.nativeElement, rs);
      //       rs.delete();
      //       this._imageFilterURL.redness_v2 = this.canvasFilter.nativeElement.toDataURL('image/jpeg');
      //       this.canvas.setOverlayImage(this._imageFilterURL.redness_v2, this._sliderEv);
      //       this._spinner.hide('filter-spinner')
      //     } catch (err) {
      //       console.log(err)
      //       this._spinner.hide('filter-spinner')
      //       this._modal.openNotification(this._ngxTryAgainText);
      //       this.filterModeChange.emit(0)
      //     }
      //   }, 100);
      //   break;
      case 2:
        if (this._imageFilterURL.redness_v1) {
          this.canvas.setOverlayImage(this._imageFilterURL.redness_v1, this._sliderEv);
          this._spinner.hide('filter-spinner')
          return;
        }
        setTimeout(async () => {
          try {
            if (!this._bgr)
              this._bgr = cv.imread(this.imgFilter.nativeElement);
            const rs = this.imageFilterService.filterRedness(this._bgr);
            cv.imshow(this.canvasFilter.nativeElement, rs);
            rs.delete();
            this._imageFilterURL.redness_v1 = this._objectUrlService.createObjectUrl(await canvasToBlob(this.canvasFilter.nativeElement, 'image/jpeg', 0.95));
            this.canvas.setOverlayImage(this._imageFilterURL.redness_v1, this._sliderEv);
            this._spinner.hide('filter-spinner')
          } catch (err) {
            console.log(err)
            this._spinner.hide('filter-spinner')
            this._modal.openNotification(this._ngxTryAgainText);
            this.filterModeChange.emit(0)
          }
        }, 100);
        break;
      case 1:
        if (this._imageFilterURL.uv) {
          this.canvas.setOverlayImage(this._imageFilterURL.uv, this._sliderEv);
          this._spinner.hide('filter-spinner')
          return;
        }
        setTimeout(async () => {
          try {
            if (!this._bgr)
              this._bgr = cv.imread(this.imgFilter.nativeElement);
            const rs = this.imageFilterService.filterUv(this._bgr);
            cv.imshow(this.canvasFilter.nativeElement, rs);
            rs.delete();
            this._imageFilterURL.uv = this._objectUrlService.createObjectUrl(await canvasToBlob(this.canvasFilter.nativeElement, 'image/jpeg', 0.95));
            this.canvas.setOverlayImage(this._imageFilterURL.uv, this._sliderEv);
            this._spinner.hide('filter-spinner')
          } catch (err) {
            console.log(err)
            this._spinner.hide('filter-spinner')
            this._modal.openNotification(this._ngxTryAgainText);
            this.filterModeChange.emit(0)
          }
        }, 100)
    }
  }

  private _resetFilter() {
    this._imageFilterURL = {
      evenness: null,
      uv: null,
      redness_v1: null,
      redness_v2: null
    };
    if (this._bgr) this._bgr.delete();
    this._bgr = null;
  }

  private _setUpDrawingSettings(){
    if (this.isHub) {
      lastValueFrom(this.dataService.getSettings())
        .then((result) => {
          this.drawingColor = result.color || DrawingConstant.DEFAULT_COLOR;
          this.fontSize = result.fontSize || DrawingConstant.DEFAULT_FONTSIZE;
          this.lineWidth = result.lineWidth || DrawingConstant.DEFAULT_LINEWIDTH;
          this.highlightOpacity = result.highlightOpacity || DrawingConstant.DEFAULT_HLOPACITY;
          this.highlightWidth = result.highlightWidth || DrawingConstant.DEFAULT_HLWIDTH;
          this.injectionsRadius = result.injectionsRadius || DrawingConstant.DEFAULT_INJECTIONS_RADIUS;
        })
        .catch((err) => {
          console.error('Error fetching settings from hub:', err.message);
        });
    } else {
      // if not hub load setting from localStorage
      this.drawingColor = localStorage.getItem('drawingColor') || DrawingConstant.DEFAULT_COLOR;
      this.fontSize = +(localStorage.getItem('fontSize') ?? DrawingConstant.DEFAULT_FONTSIZE);
      this.lineWidth = +(localStorage.getItem('lineWidth') ?? DrawingConstant.DEFAULT_LINEWIDTH);
      this.highlightOpacity = +(localStorage.getItem('highlightOpacity') ?? DrawingConstant.DEFAULT_HLOPACITY);
      this.highlightWidth = +(localStorage.getItem('highlightWidth') ?? DrawingConstant.DEFAULT_HLWIDTH);
      this.injectionsRadius = +(localStorage.getItem('injectionsRadius') ?? DrawingConstant.DEFAULT_INJECTIONS_RADIUS);

      console.log('Settings loaded from localStorage successfully.');
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
    this.innerHeight = event.target.innerHeight;
  }

  // private attributes
  private _imageData!: string
  private _filterMode = 0
  private _bgr!: Mat | null
  private _isSliding = false
  private _imageFilterURL!: {
    redness_v1: string | null
    redness_v2: string | null
    evenness: string | null
    uv: string | null
  }
  private _sliderEv!: MouseEvent | TouchEvent
  private _lastMlValue = 0
  private _lastUnitValue = 0
  private _url: string | ArrayBuffer | null = '';
  private _ngxTryAgainText!: string;
  private _ngxSomeErrorText!: string;
  private _ngxImageReadyText!: string;
  private _onLangChangeSub!: Subscription;
  private _ngxFace!: string;

  //static
  // properties
  isSettingsTabShown = false;
  filterButtons = MODE_BUTTONS
  toolbarItems = cloneDeep(TOOLBAR_ITEMS);
  lineSizeChoices = cloneDeep(LINE_SIZES);
  fontSizeChoices = cloneDeep(FONT_SIZES);
  highlightSizeChoices = cloneDeep(HIGHLIGHT_SIZES);
  highlightOpacityChoices = cloneDeep(HIGHLIGHT_OPACITY);
  colorChoices = cloneDeep(COLOR_PICKER);
  isLineSizeAdditional = false;
  isFontSizeAdditional = false;
  unitsValues!: number[]
  mlValues!: number[]
  isUnits = true
  unit = 0
  isLoading = true
  loadingText!: string
  isError = false
  isCrossOrPoint = false
  sliderLeft = 50
  currentUnitValue = 0
  currentMlValue = 0
  ruler = {
    top: 'calc(100% - 100px)',
    isTop: false
  };
  bgImage!: string
  hasBackground = true
  isProcessingBackground = false
  lineWidth: number = DrawingConstant.DEFAULT_LINEWIDTH
  fontSize: number = DrawingConstant.DEFAULT_FONTSIZE
  highlightWidth: number = DrawingConstant.DEFAULT_HLWIDTH
  drawingColor: string = DrawingConstant.DEFAULT_COLOR
  highlightOpacity: number = DrawingConstant.DEFAULT_HLOPACITY
  injectionsRadius: number = DrawingConstant.DEFAULT_INJECTIONS_RADIUS
  isImageLoaded = false
  isFaceEnabled = true;
  innerWidth: number = window.innerWidth;
  innerHeight: number = window.innerHeight;
  @Input() uuid!: string

  // output
  @Output() retry: EventEmitter<null> = new EventEmitter()
  @Output() filterModeChange: EventEmitter<number> = new EventEmitter();
  @Output() faceSimulation: EventEmitter<null> = new EventEmitter();
  // input

  @Input() set imageData(value: string) {
    if (!value) return;
    this._imageData = this.imgFilter.nativeElement.src = value;
    this._resetFilter();
    this.canvas.removeOverlayImage();
    this.canvas.browseImage(this._imageData, this.canvasBounds.nativeElement.clientHeight, this.canvasBounds.nativeElement.clientWidth);
    this.filterModeChange.emit(0);
  }

  get filterMode(): number {
    return this._filterMode;
  }

  set filterMode(mode) {
    if (!this._imageData) {
      this._modal.openNotification(this._ngxImageReadyText);
      return;
    }
    if (mode) {
      this._spinner.show('filter-spinner')
    }
    this._filterMode = mode;
    this.filterModeChange.emit(mode);
    this._filterImage(mode);
  }

  get isHub() {
    return ClientType.HUB === this._appService.getClientType();
  }

  get canUseTreatment() {
    return this.isHub;
  }

  get toolbarExtendClasses() {
    return this.canUseTreatment ? "w-38" : "w-48";
  }

  // viewchild
  @ViewChild('canvas')
  canvas!: CanvasComponent;
  @ViewChild('imgFilter')
  imgFilter!: ElementRef<HTMLImageElement>
  @ViewChild('canvasFilter')
  canvasFilter!: ElementRef<HTMLCanvasElement>
  @ViewChild('canvasBound')
  canvasBounds!: ElementRef<HTMLDivElement>
}
