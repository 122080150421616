import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {  NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom, Subscription } from 'rxjs';
import { DataService, Treatment } from 'src/app/proposer/services/data.service';
import { ModalService } from 'src/app/proposer/services/modal.service';


@Component({
  selector: 'app-treatment-bar',
  templateUrl: './treatment-bar.component.html',
  styleUrls: ['./treatment-bar.component.css'],
})
export class TreatmentBarComponent implements OnInit, OnDestroy {
  translations: {[key: string]: string} = {}
  private _temp = '';
  private _onLangChangeSub!: Subscription;
  // properties
  constructor(private _modal: ModalService,
     private _dataService: DataService,
     private _spinner: NgxSpinnerService,
     private _route: ActivatedRoute,
     private _translate: TranslateService)
  {
    this._translate.get(["treatment.create",
      "treatment.createdTreatment",
      "treatment.duplicatedName",
      "treatment.failedToCreateTreatment",
      "treatment.update",
      "treatment.successfullyUpdated",
      "treatment.failedToUpdate",
      "shared.confirm",
      "treatment.removeAssessmentConfirmation",
      "treatment.successfullyDisabledTreatment",
      "treatment.failedToDisableTreatment"
    ]).subscribe((value) => {
      this.translations = value
    })
  }
  ngOnDestroy(): void {
    if(this._onLangChangeSub){
      this._onLangChangeSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this._uuid = this._route.snapshot.queryParams['uuid']
    this._onLangChangeSub = this._translate.onLangChange.subscribe(() => {
      this._translate.get(["treatment.create",
        "treatment.createdTreatment",
        "treatment.duplicatedName",
        "treatment.failedToCreateTreatment",
        "treatment.update",
        "treatment.successfullyUpdated",
        "treatment.failedToUpdate",
        "shared.confirm",
        "treatment.removeAssessmentConfirmation",
        "treatment.successfullyDisabledTreatment",
        "treatment.failedToDisableTreatment"
      ]).subscribe((value) => {
        this.translations = value
      })
    })
    this._getTreatments();
  }

  // properties
  treatmentList: Treatment[] = [];
  isTreatmentEditorShown = false;
  // event method
  onAddTreatment() {
    this._modal.openTreatmentForm(this.translations['treatment.create'])
      .then(res => {
        if(res) {
          this._spinner.show('tm-spinner')
          lastValueFrom(this._dataService.postTreatment(res))
            .then(t => {
              this._modal.openNotification(this.translations['treatment.createdTreatment'])
              this.treatmentList.push(t)
            }).catch(err => {
              this._modal.openNotification(err.status == 422? this.translations['treatment.duplicatedName']: this.translations['treatment.failedToCreateTreatment'])
            }).finally(() => {
              this._spinner.hide('tm-spinner')
            })
        }
      })
      .catch(err => {
        err.message()
      })
      .finally(() => {
        //Todo
      })
  }

  onClickTreatment(ev: Event, treatment: Treatment) {
    this._modal.openTreatmentForm(this.translations['treatment.update'], treatment.name)
      .then(res => {
        if(res) {
          this._spinner.show('tm-spinner')
          lastValueFrom(this._dataService.putTreatment(treatment._id, res))
            .then(() => {
              treatment.name = res
              this._modal.openNotification(this.translations['treatment.successfullyUpdated'])
            }).catch((err) => {
              err.message()
              this._modal.openNotification(this.translations['treatment.failedToUpdate'])
            }).finally(() => {
              this._spinner.hide('tm-spinner')
            });
        }
      })
  }

  onDelTreatment(ev: Event, treatment: Treatment) {
    ev.stopPropagation()
    this._modal.openConfirm(this.translations['shared.confirm'], this.translations['treatment.removeAssessmentConfirmation'])
      .then((result) => {
        if(result) {
          this._spinner.show('tm-spinner')
          lastValueFrom(this._dataService.delTreatment(treatment))
          .then(() => {
            this.treatmentList = this.treatmentList.filter(t => t._id != treatment._id)
            this._modal.openNotification(this.translations['treatment.successfullyDisabledTreatment'])
          }).catch(() => {
            this._modal.openNotification(this.translations['treatment.failedToDisableTreatment'])
          }).finally(() => {
            this._spinner.hide('tm-spinner')
          });
        }
      }).catch((err) => {
        err.message();

      });
  }

  onTreatmentDragStart(ev: any, value: string): void {
    ev.dataTransfer.dropEffect = "move";
    ev.dataTransfer.effectAllowed = "move";
    ev.dataTransfer.setData('text', value);
  }

  onToggleTreatmentEditor(): void {
    this.isTreatmentEditorShown = !this.isTreatmentEditorShown;
  }

  refresh() {
    this._getTreatments()
  }
  // private
  private _uuid!: string

  private _getTreatments() {
    this._spinner.show('tm-spinner')
    lastValueFrom(this._dataService.getTreatments())
      .then((result) => {
        this.treatmentList = [...result]
      }).catch((err) => {
        this._modal.openNotification(err.message)
      }).finally(() => {
        this._spinner.hide('tm-spinner')
      })
  }
}
