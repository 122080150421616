import { HttpClient } from '@angular/common/http';
import { forwardRef, Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { lastValueFrom, } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ProposerModule } from 'src/app/proposer/proposer.module';

export interface Assessment {
    uuid: string,
    name: string,
    type: string,
    enabled: boolean
    attached?: boolean
}
export interface Token {
  access_token: string
}
export interface Config {
  url: string
  contentType: string
  retry?: number
}
export interface Patient {
  first_name: string
  last_name: string
  date_of_birth: string
}
export interface HubImage {
  uuid: string
  manual: string
  file_url: string
  image_type: string
  med_level: number
  module_type: string
  origin_image_uuid: string | null
  thumb_big: string
  thumb_small: string
  patient_uuid: string
  consultation_uuid: string
  taken_at: string
  comment: string
  second_opinion?: boolean
  bodypart_id: string
  bodypart_name: string
  bodyplace: {
    x: string,
    y: string,
    name: string,
    side: string,
    id: string
  }
  ruler_info: {
    preset_name : string,
    pixels_per_millimeter: number,
    lens_visible_diameter: number
  }
  treatment: {
    uuid: string,
    name: string
  }
}

@Injectable({
  providedIn: forwardRef(() => ProposerModule)
})
export class ImageService {

  private _apiUrl: string = environment.domain + '/api/v2/'

  constructor(private _datePipe: DatePipe, private _httpClient: HttpClient, private _activeRoute: ActivatedRoute, private _deviceDetector: DeviceDetectorService) {
  }

  getImage(token: string, uuid: string) {
    return this._httpClient.get<HubImage>(this._apiUrl.concat('images/', uuid), {
      headers: {
        'Access-Token': token
      }
    })
  }

  getPatient(patient_uuid: string) {
    const token = sessionStorage.getItem('hub-token') || ''
    return this._httpClient.get<Patient>(this._apiUrl.concat('patients/', patient_uuid), {
      headers: {
        'Access-Token': token
      },
    })
  }

  async uploadImage(token: string, file: File, image: HubImage) {
    const fd: FormData = new FormData();
    fd.append('file', file, 'edited.jpg');
    fd.append('patient_uuid', image.patient_uuid);
    fd.append('consultation_uuid', image.consultation_uuid);
    fd.append('taken_at', this._datePipe.transform(Date.now(), 'YYYY-MM-ddTHH:mm:ssZ') as string);
    fd.append('image_type', 'edited');
    fd.append('med_level', '7');
    fd.append('origin_image_uuid', image.uuid);
    fd.append('module_type', image.module_type);
    if(image.treatment) fd.append('treatment_uuid', image.treatment.uuid)
    fd.append('manual', image.manual);
    if(image.comment) fd.append('comment', image.comment);
    fd.append('bodypart_id', image.bodypart_id);
    if(image.bodyplace) {
      fd.append('bodyplace[x]', image.bodyplace.x);
      fd.append('bodyplace[y]', image.bodyplace.y);
      fd.append('bodyplace[side]', image.bodyplace.side);
    }
    if(image.ruler_info) {
      fd.append('ruler_info[preset_name]', image.ruler_info.preset_name);
      fd.append('ruler_info[pixels_per_millimeter]', image.ruler_info.pixels_per_millimeter.toString());
      fd.append('ruler_info[lens_visible_diameter]', image.ruler_info.lens_visible_diameter.toString());
    }

    return lastValueFrom(this._httpClient.post<HubImage>(this._apiUrl.concat('images'), fd, {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      }
    }))
  }

  attachTreatment(token: string, image_uuid: string, treatment_uuid: string) {
    return this._httpClient.post(this._apiUrl.concat(`images/${image_uuid}/assessments`), {assessment_uuid: treatment_uuid}, {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      }
    })
  }

  postTreatment(token: string, name: string) {
    return this._httpClient.post<Assessment>(this._apiUrl.concat('assessments'), { name: name, type: 'treatment' }, {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      }
    })
  }

  detachTreatment(token: string, uuid: string, treatment_uuid: string) {
    return this._httpClient.delete(this._apiUrl.concat(`images/${uuid}/assessments/${treatment_uuid}`), {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      }
    })
  }

  disableTreatment(token: string, treatment_uuid: string) {
    return this._httpClient.put(this._apiUrl.concat(`assessments/${treatment_uuid}`), {
      enabled: false
    }, {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      }
    })
  }

  updateTreatment(token: string, uuid: string, name: string) {
    return this._httpClient.put(this._apiUrl.concat(`assessments/${uuid}`), {
      name: name,

    }, {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      }
    })
  }

  getTreatments(token: string) {
    return this._httpClient.get<Assessment[]>(this._apiUrl.concat(`assessments`), {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      },
      params: {
        enabled: true,
        type: 'treatment'
      }
    })
  }

  getAttachedTreatments(token: string, uuid: string) {
    return this._httpClient.get<Assessment[]>(this._apiUrl.concat(`images/${uuid}/assessments`), {
      headers: {
        'Accept': '*/*',
        'Access-Token': token,
      },
      params: {
        enabled: true,
        type: 'treatment'
      }
    })
  }
}
