import { forwardRef, Injectable, OnDestroy } from '@angular/core';
import { ProposerModule } from '../proposer.module';

@Injectable({
  providedIn: forwardRef(() => ProposerModule)
})
export class ObjectUrlService implements OnDestroy {
  private objectUrls: Set<string> = new Set();

  constructor() {
    // Listen for browser unload events
    window.addEventListener('beforeunload', this.revokeAllObjectUrls.bind(this));
  }


  createObjectUrl(file: Blob): string {
    const url = URL.createObjectURL(file);
    this.objectUrls.add(url);
    return url;
  }

  revokeAllObjectUrls(): void {
    this.objectUrls.forEach((url) => URL.revokeObjectURL(url));
    this.objectUrls.clear();
  }

  ngOnDestroy(): void {
    this.revokeAllObjectUrls();
  }
}
