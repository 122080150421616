import { HttpClient } from '@angular/common/http';
import { forwardRef, Injectable } from '@angular/core';
import { Token } from '../../proposer/services/image.service';
import { DeviceUUID } from 'device-uuid';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { AuthModule } from '../auth.module';

@Injectable({
  providedIn: forwardRef(() => AuthModule)
})
export class AuthService {

  private _apiHubUrl: string = environment.domain + '/api/v2/'
  private _apiProposerUrl: string = environment.url_be + '/api/v1'

  constructor(private _http: HttpClient, private _deviceDetector: DeviceDetectorService) { }

  authHub(sec: string) {
    const body = {
      device_id: new DeviceUUID().get(),
      device_name: `proposer_${this._deviceDetector.getDeviceInfo().os}${this._deviceDetector.getDeviceInfo().browser}`,
      device_type: 'proposer_web'
    }
    return this._http.post<Token>(this._apiHubUrl.concat('devices/oauth'), JSON.stringify(body), {
      params: {
        sec: sec
      },
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }

  authProposer(token: string) {
    return this._http.post<void>(this._apiProposerUrl.concat('/auth'), {}, {
      headers: {
        'access-token': token
      },
      withCredentials: true,
    })
  }
}
