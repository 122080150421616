import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalService } from 'src/app/proposer/services/modal.service';
import { UserActionService } from 'src/app/shared/services/user-action.service';
import { ClientType } from 'src/app/utils/const';

@Component({
  selector: 'app-bottombar',
  templateUrl: './bottombar.component.html',
  styleUrls: ['./bottombar.component.css']
})
export class BottombarComponent implements OnInit {

  @Input() isDrawing!: boolean
  @Input() isRecommend!: boolean
  @Input() isFace!: boolean
  @Input() isPrint!: boolean
  @Input() isCropSuccess!: boolean
  @Input() clientType!: string
  @Output() isDrawingChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() isRecommendChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() isFaceChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() isPrintChange: EventEmitter<boolean> = new EventEmitter<boolean>()
  @Output() saveBack: EventEmitter<string> = new EventEmitter<string>();
  
  constructor(private _modalService: ModalService, private _userActionService: UserActionService, private _spinner: NgxSpinnerService) {}
  
  ngOnInit(): void {
    this._userActionService.backDrawings$.subscribe(() => this.onToDrawings());
  }

  // event method
  onChangeScreen(isDrawing: boolean) {
    if(!isDrawing && !this.isCropSuccess) {
      this._modalService.openNotification('Image not ready or has failed to crop')
    } else {
      this.isDrawing = isDrawing;
      this.isPrint = false;
      this.isDrawingChange.emit(this.isDrawing);
      this.isPrintChange.emit(this.isPrint);
    }
  }

  onToDrawings() {
    this.isDrawingChange.emit(true);
    this.isFaceChange.emit(false);
    this.isRecommendChange.emit(false);
    this.isPrintChange.emit(false);
    this._userActionService.announceTitleChanged("Drawings");
  }

  onToRecommendation() {
    if(!this.isCropSuccess) {
      this._modalService.openNotification('Image not ready or has failed to crop')
    } else {
      this.isDrawingChange.emit(false);
      this.isFaceChange.emit(false);
      this.isRecommendChange.emit(true);
      this.isPrintChange.emit(false);
      this._userActionService.announceTitleChanged("Recommendation");
    }
  }

  onSaveToDrawings() {
    this._spinner.show('filter-spinner')
    setTimeout(() => {
      this._userActionService.announceReplaceImage(true);
    }, 200);
    this.onToDrawings();
  }

  onSaveBack() {
    this.saveBack.emit(this.clientType);
  }

  onMoveToPrint(): void {
    this.isPrint = true;
    this.isPrintChange.emit(this.isPrint);
  }

  get canUseRecommendation() {
    return this.clientType == ClientType.HUB;
  }
}